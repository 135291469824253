import React, { useEffect } from "react";
import { connect } from "react-redux";
import ReduxStore from "store/store.types";
import { fetchVotingRounds, postVotingRounds } from "store/admin/admin.actions";
import VotingRounds from "types/votingrounds.types";

const RoundsSection: React.FC<RoundsSectionProps> = ({
    rounds,
    postVotingRounds,
    fetchVotingRounds,
}) => {
    useEffect(() => {
        fetchVotingRounds();
    }, [fetchVotingRounds]);

    if (!rounds) {
        return <p>Loading voting rounds...</p>;
    }

    const updateRoundState = (round: string, value: boolean) => () => {
        const updatedRounds = {
            ...rounds,
            [round]: value,
        };
        postVotingRounds(updatedRounds);
    };

    return (
        <div>
            {Object.keys(rounds).map(round => (
                <div style={{ display: "flex", marginTop: 8 }} key={round}>
                    <p style={{ flex: 1 }}>{round}</p>
                    <button
                        onClick={updateRoundState(round, false)}
                        className={
                            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                            // @ts-ignore
                            rounds[round]
                                ? "btn btn-outline-primary"
                                : "btn btn-primary"
                        }
                    >
                        Allow voting
                    </button>
                    <button
                        onClick={updateRoundState(round, true)}
                        className={
                            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                            // @ts-ignore
                            rounds[round]
                                ? "btn btn-primary"
                                : "btn btn-outline-primary"
                        }
                    >
                        Block voting
                    </button>
                </div>
            ))}
        </div>
    );
};

const mapStateToProps = (store: ReduxStore) => ({
    rounds: store.admin.rounds,
});
const mapDispatchToProps = { postVotingRounds, fetchVotingRounds };
const ConnectedRoundsSection = connect(
    mapStateToProps,
    mapDispatchToProps
)(RoundsSection);

export default ConnectedRoundsSection;

interface RoundsSectionProps {
    rounds: VotingRounds | null;
    fetchVotingRounds: () => void;
    postVotingRounds: (rounds: VotingRounds) => void;
}
