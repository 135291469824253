import AdminState, { AdminDispatches } from "./admin.types";
import AdminActions from "./admin.constants";

export const initialAdminState: AdminState = {
    isLoggedIn: false,
    username: null,
    authToken: null,
    contestants: null,
    rounds: null,
    votingAverages: null,
};

const adminReducer = (
    state = initialAdminState,
    action: AdminDispatches
): AdminState => {
    switch (action.type) {
        case AdminActions.AUTHENTICATE:
            return {
                ...state,
                isLoggedIn: action.payload.isLoggedIn
                    ? action.payload.isLoggedIn
                    : false,
                username: action.payload.username
                    ? action.payload.username
                    : "",
                authToken: action.payload.authToken
                    ? action.payload.authToken
                    : "",
            };
        case AdminActions.UPDATE_CONTESTANTS:
            return {
                ...state,
                contestants: action.payload.contestants
                    ? action.payload.contestants
                    : null,
            };
        case AdminActions.UPDATE_VOTING_ROUNDS:
            return {
                ...state,
                rounds: action.payload.rounds || null,
            };
        case AdminActions.UPDATE_VOTING_AVERAGES:
            return {
                ...state,
                votingAverages: action.payload.votingAverages || null,
            };
        default:
            return state;
    }
};

export default adminReducer;
