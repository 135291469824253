import React from "react";
import { Navbar, Nav, Button } from "react-bootstrap";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";

export const NabarButton: React.FC<{
    navigateToPage?: string;
    onClick?: () => void;
    text: string;
}> = ({ navigateToPage, text, onClick }) => {
    const history = useHistory();

    return (
        <Button
            style={{ marginLeft: 8 }}
            variant="link"
            onClick={() => {
                if (navigateToPage) {
                    history.push(navigateToPage);
                }
                if (onClick) {
                    onClick();
                }
            }}
        >
            {text}
        </Button>
    );
};

export const MainNavbar = ({
    logout,
    downloadVotesCSV,
}: {
    logout: Function;
    downloadVotesCSV: () => void;
}) => {
    const history = useHistory();

    return (
        <StyledNavBar bg="light">
            <Navbar.Brand as={Link} to="/">
                Fantescy Admin Dashboard
            </Navbar.Brand>
            <Navbar.Collapse>
                <Nav className="mr-auto" />
            </Navbar.Collapse>
            <NabarButton navigateToPage="/videos" text="Videos" />
            <NabarButton navigateToPage="/rounds" text="Voting rounds" />
            <NabarButton onClick={downloadVotesCSV} text="Votes export" />
            <NabarButton navigateToPage="/news" text="News" />
            <NabarButton navigateToPage="/contestants" text="Contestants" />
            <Button
                onClick={() => {
                    logout();
                    history.push("/login");
                }}
            >
                Log Out
            </Button>
        </StyledNavBar>
    );
};

const StyledNavBar = styled(Navbar)`
    color: white;
`;
