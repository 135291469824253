import React, { ReactNode } from "react";
import { Redirect } from "react-router-dom";

export const isAuthenticated = () => {
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email");
    return token && email;
};

const requireAuth = (Component: React.FC<{}>) => (): ReactNode => {
    return !isAuthenticated() ? <Redirect to="/login" /> : <Component />;
};

export default requireAuth;
