import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";

export const NewsItemPrompt = ({
    handleSubmit,
}: {
    handleSubmit: Function;
}) => {
    const [href, setHref] = useState("");
    const [logoUrl, setLogoUrl] = useState("");

    const handleSubmitWrapper = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        handleSubmit({ href, logo_url: logoUrl });
    };

    return (
        <Form onSubmit={handleSubmitWrapper}>
            <Form.Group controlId="newsItemForm">
                <Form.Label>News link</Form.Label>
                <Form.Control
                    placeholder="News link"
                    value={href}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setHref(e.target.value)
                    }
                />
            </Form.Group>

            <Form.Group controlId="newsItemForm">
                <Form.Label>Logo url</Form.Label>
                <Form.Control
                    placeholder="Logo url"
                    value={logoUrl}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setLogoUrl(e.target.value)
                    }
                />{" "}
            </Form.Group>
            <Button variant="primary" type="submit">
                Add news item
            </Button>
        </Form>
    );
};
