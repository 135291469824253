import React from "react";
import ConnectedRoundsSection from "sections/RoundsSection/RoundsSection";

const Rounds = () => (
    <div>
        <h1>Rounds</h1>
        <ConnectedRoundsSection />
    </div>
);

export default Rounds;
