import React from "react";
import VideoForm from "components/VideoForm/VideoForm";
import { ListGroupItem } from "react-bootstrap";
import { VideoItemProps } from "types/videoItem.types";

const VideoItem: React.FC<VideoItemProps> = ({ changeVideoItem, videos }) => {
    return (
        <ListGroupItem>
            <VideoForm changeVideoItem={changeVideoItem} videos={videos} />
        </ListGroupItem>
    );
};

export default VideoItem;
