import { createStore, applyMiddleware, combineReducers, Store } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import ReduxStore from "./store.types";
import adminReducer from "./admin/admin.reducer";

const middleware = [thunk];

const rootReducer = combineReducers({
    admin: adminReducer,
});

const store: Store<ReduxStore> = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
