import React, { useEffect } from "react";
import { Card, ListGroup } from "react-bootstrap";
import { checkLoggedIn } from "store/admin/admin.actions";
import { connect } from "react-redux";
import ContestantItem from "containers/Contestant/Contestant";

const ContestantSection: React.FC<{
    token: string;
    email: string;
    checkLoggedIn: Function;
}> = ({ token, email, checkLoggedIn }) => {
    useEffect(() => {
        const func = async () => {
            if (!email || !token) await checkLoggedIn();
        };
        func();
    }, [email, token, checkLoggedIn]);

    return (
        <div>
            <Card>
                <ListGroup variant="flush">
                    <ContestantItem key="contestantitem" />
                </ListGroup>
            </Card>
        </div>
    );
};

const mapStateToProps = (state: {
    admin: { authToken: string; username: string };
}) => ({
    token: state.admin.authToken,
    email: state.admin.username,
});
const mapDispatchToProps = {
    checkLoggedIn,
};
const ConnectedContestantSection = connect(
    mapStateToProps,
    mapDispatchToProps
)(ContestantSection);

export default ConnectedContestantSection;
