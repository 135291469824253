import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import AdminDashboard from "./AdminDashboard";
import store from "./store/store";

const ReduxApplication = () => (
    <Provider store={store}>
        <AdminDashboard />
    </Provider>
);

// eslint-disable-next-line no-undef
ReactDOM.render(<ReduxApplication />, document.getElementById("root"));
