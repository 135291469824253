import React from "react";
import NewsForm from "components/NewsForm/NewsForm";
import { NewsItemProps } from "types/newsitem.types";
import { ListGroupItem } from "react-bootstrap";

const NewsItem: React.FC<NewsItemProps> = ({
    href,
    logo_url,
    deleteThis,
    changeNewsItem,
}) => {
    return (
        <ListGroupItem>
            <NewsForm
                href={href}
                logo_url={logo_url}
                deleteThis={deleteThis}
                changeNewsItem={changeNewsItem}
            />
        </ListGroupItem>
    );
};

export default NewsItem;
