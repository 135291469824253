import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import login from "store/admin/admin.actions";
import {
    Button,
    FormGroup,
    FormControl,
    FormLabel,
    Col,
    Row,
} from "react-bootstrap";
import styled from "styled-components";
import { VideoItemProps } from "types/videoItem.types";

const getEmptyVideo = (id: number) => {
    return {
        id: `video${id}`,
        title: "",
        image_url: "",
        youtube_url: "",
        songName: "",
        country: "",
        year: "",
        ranking: "",
        artist: "",
    };
};

const VideoForm: React.FC<VideoItemProps> = ({ changeVideoItem, videos }) => {
    const vid0 = videos.find(x => x.id === "video0");
    const vid1 = videos.find(x => x.id === "video1");
    const [video, setVideo] = useState(vid0 || getEmptyVideo(0));
    const [video2, setVideo2] = useState(vid1 || getEmptyVideo(1));
    const canSendForm = Array.from(Object.values(video)).every(
        x => x.length > 0
    );

    useEffect(() => {
        setVideo(vid0 || getEmptyVideo(0));
        setVideo2(vid1 || getEmptyVideo(1));
    }, [videos, vid0, vid1]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        changeVideoItem([video, video2]);
    };

    const setWithKeyv1 = (key: string, e: string) => {
        setVideo({ ...video, [key]: e });
    };

    const setWithKeyv2 = (key: string, e: string) => {
        setVideo2({ ...video2, [key]: e });
    };

    const getFormv1 = () => {
        return Array.from(Object.entries(video)).map(
            (obj: [string, string], index: number) => {
                if (obj[0] !== "id") {
                    return (
                        <FormGroup
                            controlId={`${obj[0] + index}video1`}
                            key={`video1-${obj[0]}`}
                        >
                            <FormLabel>{obj[0]}:</FormLabel>
                            <FormControl
                                autoFocus
                                type="name"
                                key={`video1-control-${obj[0]}`}
                                value={obj[1]}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => setWithKeyv1(obj[0], e.target.value)}
                            />
                        </FormGroup>
                    );
                }
                return "";
            }
        );
    };

    const getFormv2 = () => {
        return Array.from(Object.entries(video2)).map(
            (obj: [string, string], index: number) => {
                if (obj[0] !== "id") {
                    return (
                        <FormGroup
                            controlId={`${obj[0] + index}video2`}
                            key={`video2-${obj[0]}`}
                        >
                            <FormLabel>{obj[0]}:</FormLabel>
                            <FormControl
                                autoFocus
                                type="name"
                                key={`video2-control-${obj[0]}`}
                                value={obj[1]}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => setWithKeyv2(obj[0], e.target.value)}
                            />
                        </FormGroup>
                    );
                }
                return "";
            }
        );
    };

    return (
        <form onSubmit={handleSubmit} key="videoform">
            <Row>
                <Col>
                    <VideoFormStyle>
                        video 1:
                        {getFormv1()}
                        <br />
                        video 2:
                        <br />
                        {getFormv2()}
                        <Row>
                            <StyledButton
                                variant="success"
                                block
                                disabled={!canSendForm}
                                type="submit"
                            >
                                Save
                            </StyledButton>
                        </Row>
                    </VideoFormStyle>
                </Col>
            </Row>
        </form>
    );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {
    login,
};
const ConnectedVideoForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(VideoForm);

const StyledButton = styled(Button)`
    max-width: 6rem;
    margin-left: 1rem;
`;

const VideoFormStyle = styled.div`
    padding: 1em;
    width: 35rem;
`;

export default ConnectedVideoForm;
