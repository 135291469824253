import axios from "axios";
import { API_URL } from "../config";

export const getFromAPIWithAuth = async (
    endpoint: string,
    email?: string,
    token?: string
) => {
    const headers = token
        ? {
              headers: {
                  "X-Auth-Email": email,
                  "X-Auth-Token": token,
              },
          }
        : {
              headers: {
                  "X-Auth-Email": email,
              },
          };
    const res = await axios.get(API_URL + endpoint, headers);

    return res.data.data;
};

export const postToAPIWithAuth = async <T>(
    endpoint: string,
    email: string,
    token: string,
    data: T
) => {
    const res = await axios.post(API_URL + endpoint, data, {
        headers: {
            "X-Auth-Email": email,
            "X-Auth-Token": token,
        },
    });

    return res.data.data;
};

export const putToAPIWithAuth = async <T>(
    endpoint: string,
    email: string,
    token: string,
    data: T
) => {
    const res = await axios.put(API_URL + endpoint, data, {
        headers: {
            "X-Auth-Email": email,
            "X-Auth-Token": token,
        },
    });

    return res.data.data;
};

export const delToAPIWithAuth = async <T>(
    endpoint: string,
    email: string,
    token: string,
    data: T
) => {
    const res = await axios.delete(API_URL + endpoint, {
        data,
        headers: {
            "X-Auth-Email": email,
            "X-Auth-Token": token,
        },
    });

    return res.data.data;
};
