/* eslint-disable prefer-template, no-undef */
const exportCSV = async (csvString: string) => {
    const downloadableCsvString = "data:text/csv;charset=utf-8," + csvString;
    const encodedcsv = encodeURI(downloadableCsvString);
    window.open(encodedcsv);
    const link = document.createElement("a");
    link.setAttribute("href", encodedcsv);
    const csvName = "votedata.csv";
    link.setAttribute("download", csvName);
    document.body.appendChild(link);
    link.click();
};

export default exportCSV;
