import React, { useState } from "react";
import { connect } from "react-redux";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import ReduxStore from "../../store/store.types";
import { addContestant } from "../../store/admin/admin.actions";
import { Contestant } from "../../types/contestantItem.types";
import { SingleContestantForm } from "../../components/ContestantForm/ContestantForm";
import allCountries from "./allCountries";

const ContestantsAdd: React.FC<ContestantsAddProps> = ({
    currentContestants,
    addContestant,
}) => {
    const [contestantToCreate, setContestantToCreate] = useState<string | null>(
        null
    );
    const history = useHistory();

    if (!currentContestants) {
        return null;
    }

    const countriesInState = currentContestants.map(c => c.country);
    const countriesNotInState = allCountries.filter(
        c => !countriesInState.includes(c)
    );

    const saveNewContestant = (contestants: Contestant[]) => {
        const contestantToAdd = contestants[0];
        addContestant(contestantToAdd);
        history.push("/contestants");
    };

    const ContestantInput = () => {
        if (!contestantToCreate) {
            return (
                <DropdownButton
                    id="country-select"
                    onSelect={setContestantToCreate}
                    title="Select country to add"
                >
                    {countriesNotInState.map(c => (
                        <Dropdown.Item eventKey={c} key={c}>
                            {c}
                        </Dropdown.Item>
                    ))}
                </DropdownButton>
            );
        }
        const newContestant: Contestant = {
            country: contestantToCreate,
            artist: "",
            song: "",
            participates_in_semifinal: "1",
            youtube_song_url: "",
            sortorder_sf: 99,
            sortorder_gf: 99,
        };
        return (
            <SingleContestantForm
                contestant={newContestant}
                postContestants={saveNewContestant}
                deleteContestant={() => {
                    // eslint-disable-next-line no-undef,no-alert
                    window.alert("Cannot delete uncreated country");
                }}
            />
        );
    };

    return (
        <div>
            <h1>Add Contestant</h1>
            <ContestantInput />
        </div>
    );
};

const mapStateToProps = (state: ReduxStore) => ({
    currentContestants: state.admin.contestants,
});
const mapDispatchToProps = { addContestant };
export default connect(mapStateToProps, mapDispatchToProps)(ContestantsAdd);

interface ContestantsAddProps {
    currentContestants: Contestant[] | null;
    addContestant: (contestants: Contestant) => void;
}
