import React from "react";
import ConnectedVideoSection from "sections/VideoSection/VideoSection";

const Videos = () => (
    <div>
        <h1>Videos</h1>
        <ConnectedVideoSection />
    </div>
);

export default Videos;
