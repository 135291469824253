import React from "react";
import ConnectedNewsSection from "sections/NewsSection/NewsSection";

const News = () => (
    <div>
        <h1>News</h1>
        <ConnectedNewsSection />
    </div>
);

export default News;
