import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import login, { checkLoggedIn } from "store/admin/admin.actions";
import {
    Button,
    FormGroup,
    FormControl,
    FormLabel,
    FormText,
} from "react-bootstrap";
import { LoginUser } from "types/login.types";
import { useHistory } from "react-router-dom";

const LoginForm: React.FC<LoginSectionProps> = ({
    checkLoggedIn,
    login,
    isLoggedIn,
}) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorText, setErrorText] = useState("");
    const [attempts, setAttempts] = useState(0);
    const history = useHistory();

    const canSendForm = email.length > 0 || password.length > 0;

    useEffect(() => {
        const func = async () => {
            await checkLoggedIn();
        };
        func();
        if (!isLoggedIn && attempts !== 0) {
            setErrorText("Wrong email or password. Please try again!");
        } else if (isLoggedIn && history) {
            history.push("/home");
        }
    }, [isLoggedIn, checkLoggedIn, attempts, history]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        await login({ email, password });
        setAttempts(attempts + 1);
    };

    return (
        <form onSubmit={handleSubmit}>
            <FormGroup controlId="email">
                <FormLabel>Email</FormLabel>
                <FormControl
                    autoFocus
                    type="email"
                    value={email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setEmail(e.target.value)
                    }
                />
            </FormGroup>
            <FormGroup controlId="password">
                <FormLabel>Password</FormLabel>
                <FormControl
                    value={password}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setPassword(e.target.value)
                    }
                    type="password"
                />

                <FormText className="text-muted">{errorText}</FormText>
            </FormGroup>
            <Button block disabled={!canSendForm} type="submit">
                Login
            </Button>
        </form>
    );
};

const mapStateToProps = (state: { admin: { isLoggedIn: boolean } }) => ({
    isLoggedIn: state.admin.isLoggedIn,
});

const mapDispatchToProps = {
    login,
    checkLoggedIn,
};
const ConnectedLoginForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginForm);

export default ConnectedLoginForm;

interface LoginSectionProps {
    login: (login: LoginUser) => void;
    checkLoggedIn: () => void;
    isLoggedIn: boolean;
}
