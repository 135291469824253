const allCountries = [
    "Albania",
    "Andorra",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Belarus",
    "Belgium",
    "BosniaHerzegovina",
    "Bulgaria",
    "Croatia",
    "Cyprus",
    "CzechRepublic",
    "Denmark",
    "Estonia",
    "Finland",
    "France",
    "Georgia",
    "Germany",
    "Greece",
    "Hungary",
    "Iceland",
    "Ireland",
    "Israel",
    "Italy",
    "Luxemburg",
    "Latvia",
    "Lithuania",
    "Malta",
    "Moldova",
    "Monaco",
    "Montenegro",
    "Morocco",
    "Netherlands",
    "NorthMacedonia",
    "Norway",
    "Poland",
    "Portugal",
    "Romania",
    "Russia",
    "SanMarino",
    "Serbia",
    "SerbiaMontenegro",
    "Slovakia",
    "Slovenia",
    "Spain",
    "Sweden",
    "Switzerland",
    "Turkey",
    "Ukraine",
    "UnitedKingdom",
    "Yugoslavia",
];

export default allCountries;
