import React from "react";
import styled from "styled-components";

const Logo: React.FC<{}> = () => (
    // eslint-disable-next-line global-require
    <StyledImage src={require("images/logo.svg")} />
);

const StyledImage = styled.img`
    margin-left: 40%;
    width: 20%;
`;

export default Logo;
