import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
    deleteContestant,
    getContestants,
    postContestants,
} from "store/admin/admin.actions";
import {
    Button,
    FormGroup,
    FormControl,
    FormLabel,
    Col,
    Row,
    ListGroupItem,
} from "react-bootstrap";
import styled from "styled-components";
import {
    ContestantItemProps,
    Contestant,
    ContestantSingleProps,
} from "types/contestantItem.types";

export const SingleContestantForm: React.FC<ContestantSingleProps> = ({
    postContestants,
    deleteContestant,
    contestant,
}) => {
    const [artist, setArtist] = useState(
        contestant.artist ? contestant.artist : ""
    );
    const [song, setSong] = useState(contestant.song ? contestant.song : "");
    const [youtube, setYoutube] = useState(
        contestant.youtube_song_url ? contestant.youtube_song_url : ""
    );
    const [semifinal, setSemiFinal] = useState(
        contestant.participates_in_semifinal
            ? contestant.participates_in_semifinal
            : "none"
    );
    const [grandfinal, setGrandFinal] = useState(
        contestant.placed_in_grandfinal
            ? contestant.placed_in_grandfinal
            : false
    );
    const [finalPlace, setFinalPlace] = useState(
        contestant.final_place_grandfinal?.toString()
    );
    const [sortorderSF, setSortorderSF] = useState(
        contestant.sortorder_sf.toString()
    );
    const [sortorderGF, setSortorderGF] = useState(
        contestant.sortorder_gf.toString()
    );

    const handleChangeArtist = (e: React.ChangeEvent<HTMLInputElement>) => {
        setArtist(e.target.value);
    };
    const handleChangeSong = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSong(e.target.value);
    };
    const handleChangeYoutube = (e: React.ChangeEvent<HTMLInputElement>) => {
        setYoutube(e.target.value);
    };
    const handleChangeSemiFinal = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSemiFinal(e.target.value);
    };
    const handleChangeGrandFinal = (e: React.ChangeEvent<HTMLInputElement>) => {
        setGrandFinal(e.target.checked);
    };
    const handleChangeFinalPlace = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFinalPlace(e.target.value);
    };
    const handleChangeSortorderSF = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        setSortorderSF(e.target.value);
    };
    const handleChangeSortorderGF = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        setSortorderGF(e.target.value);
    };
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        postContestants([
            {
                country: contestant.country,
                artist,
                song,
                youtube_song_url: youtube,
                participates_in_semifinal: semifinal,
                placed_in_grandfinal: grandfinal,
                final_place_grandfinal: finalPlace
                    ? parseInt(finalPlace, 10)
                    : undefined,
                sortorder_sf: parseInt(sortorderSF, 10),
                sortorder_gf: parseInt(sortorderGF, 10),
            },
        ]);
        event.preventDefault();
    };
    const handleDelete = () => {
        // eslint-disable-next-line no-undef, no-alert
        const shouldDelete = window.confirm(
            `Are you sure you want to remove ${contestant.country}`
        );
        if (shouldDelete) {
            deleteContestant(contestant.country);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <Row>
                <Col>
                    <FormLabel>
                        <b>{contestant.country}:</b>
                    </FormLabel>
                    <FormGroup
                        controlId={contestant.country}
                        style={{ display: "flex" }}
                    >
                        <StyledFormColumn>
                            <FormLabel>Artist</FormLabel>
                            <FormControl
                                autoFocus
                                type="name"
                                value={artist}
                                onChange={handleChangeArtist}
                            />
                        </StyledFormColumn>
                        <StyledFormColumn>
                            <FormLabel>Song</FormLabel>
                            <FormControl
                                autoFocus
                                type="name"
                                value={song}
                                onChange={handleChangeSong}
                            />
                        </StyledFormColumn>
                        <StyledFormColumn>
                            <FormLabel>Youtube URL</FormLabel>
                            <FormControl
                                autoFocus
                                type="name"
                                value={youtube}
                                onChange={handleChangeYoutube}
                            />
                        </StyledFormColumn>
                        <StyledFormColumn>
                            <FormLabel>Participates in semifinal?</FormLabel>
                            <FormControl
                                as="select"
                                type="name"
                                value={semifinal}
                                onChange={handleChangeSemiFinal}
                            >
                                <option>1</option>
                                <option>2</option>
                                <option>none</option>
                            </FormControl>
                        </StyledFormColumn>
                        <StyledFormColumn>
                            <FormLabel>Placed in grandfinal</FormLabel>
                            <FormControl
                                autoFocus
                                type="checkbox"
                                checked={grandfinal}
                                onChange={handleChangeGrandFinal}
                            />
                        </StyledFormColumn>
                        <StyledFormColumn>
                            <FormLabel>Place in grand final?</FormLabel>
                            <FormControl
                                autoFocus
                                type="number"
                                value={finalPlace}
                                onChange={handleChangeFinalPlace}
                            />
                        </StyledFormColumn>
                        <StyledFormColumn>
                            <FormLabel>Sortorder semi final</FormLabel>
                            <FormControl
                                autoFocus
                                type="number"
                                value={sortorderSF}
                                onChange={handleChangeSortorderSF}
                            />
                        </StyledFormColumn>
                        <StyledFormColumn>
                            <FormLabel>Sortorder grand final</FormLabel>
                            <FormControl
                                autoFocus
                                type="number"
                                value={sortorderGF}
                                onChange={handleChangeSortorderGF}
                            />
                        </StyledFormColumn>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <StyledButton variant="success" block type="submit">
                    Save
                </StyledButton>
                <StyledButton
                    variant="danger"
                    block
                    type="submit"
                    onClick={handleDelete}
                    style={{ marginTop: 0 }}
                >
                    Delete
                </StyledButton>
            </Row>
        </form>
    );
};

const ContestantForm: React.FC<ContestantItemProps> = ({
    getContestants,
    postContestants,
    deleteContestant,
    contestants,
}) => {
    useEffect(() => {
        getContestants();
    }, [getContestants]);

    const getForm = () => {
        return contestants?.map(contestant => {
            return (
                <ListGroupItem key={`${contestant.country}`}>
                    <SingleContestantForm
                        key={`${contestant.country}single`}
                        contestant={contestant}
                        postContestants={postContestants}
                        deleteContestant={deleteContestant}
                    />
                </ListGroupItem>
            );
        });
    };

    return <ContestantFormStyle>{getForm()}</ContestantFormStyle>;
};

const mapStateToProps = (state: {
    admin: {
        contestants: Contestant[];
    };
}) => ({
    contestants: state.admin.contestants,
});

const mapDispatchToProps = {
    getContestants,
    postContestants,
    deleteContestant,
};
const ConnectedContestantForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(ContestantForm);

const StyledFormColumn = styled.div`
    padding: 0.2em;
    :first-of-type {
        padding-left: 0;
    }
    :last-of-type {
        padding-right: 0;
    }
    label.form-label {
        height: 2.8em;
    }
`;

const StyledButton = styled(Button)`
    max-width: 6rem;
    margin-left: 1rem;
`;

const ContestantFormStyle = styled.div`
    padding: 1em;
    //width: 35rem;
`;

export default ConnectedContestantForm;
