import React, { useEffect, useState } from "react";
import { Card, ListGroup } from "react-bootstrap";
import { checkLoggedIn } from "store/admin/admin.actions";
import { connect } from "react-redux";
import { VideoItemSend } from "types/videoItem.types";
import VideoItem from "containers/VideoItem/Videoitem";
import { postToAPIWithAuth, getFromAPIWithAuth } from "../../utils/apiEndpoint";

const endpoint = "/api/admin/content/posts";

const addVideoItem = async (
    VideoItem: { id: string; contents: string }[],
    token: string,
    email: string
) => {
    postToAPIWithAuth<{ id: string; contents: string }[]>(
        endpoint,
        email,
        token,
        VideoItem
    );
};

const VideoSection: React.FC<{
    token: string;
    email: string;
    checkLoggedIn: Function;
}> = ({ token, email, checkLoggedIn }) => {
    const [update, doUpdate] = useState(true);
    const [videos, setVideos] = useState([
        {
            id: "video0",
            title: "",
            image_url: "",
            youtube_url: "",
            songName: "",
            country: "",
            year: "",
            ranking: "",
            artist: "",
        },
        {
            id: "video1",
            title: "",
            image_url: "",
            youtube_url: "",
            songName: "",
            country: "",
            year: "",
            ranking: "",
            artist: "",
        },
    ]);
    const updateList = () => setTimeout(() => doUpdate(!update), 100);

    useEffect(() => {
        const func = async () => {
            if (!email || !token) await checkLoggedIn();
        };
        func();
    }, [email, token, checkLoggedIn]);

    useEffect(() => {
        const func = async () => {
            const loadedEmail = email || localStorage.getItem("email");
            if (loadedEmail) {
                const videoApiData = await getFromAPIWithAuth(
                    "/api/public/content/posts",
                    loadedEmail
                );
                const videos = videoApiData;
                if (videos && videos.length > 1) {
                    const parsedVid = videos.map(
                        (x: { id: string; contents: string }) => {
                            return JSON.parse(x.contents);
                        }
                    );
                    setVideos(parsedVid);
                }
            }
        };
        func();
    }, [email, update]);

    const addVideo = async (VideoItems: VideoItemSend[]) => {
        const message = VideoItems.map(VideoItem => {
            const { id } = VideoItem;
            const contents = JSON.stringify(VideoItem);
            return { id, contents };
        });
        await addVideoItem(message, token, email);
        updateList();
    };

    return (
        <div>
            <Card>
                <ListGroup>
                    <VideoItem changeVideoItem={addVideo} videos={videos} />
                </ListGroup>
            </Card>
        </div>
    );
};

const mapStateToProps = (state: {
    admin: { authToken: string; username: string };
}) => ({
    token: state.admin.authToken,
    email: state.admin.username,
});
const mapDispatchToProps = {
    checkLoggedIn,
};
const ConnectedVideoSection = connect(
    mapStateToProps,
    mapDispatchToProps
)(VideoSection);

export default ConnectedVideoSection;
