import React, { useState } from "react";
import { connect } from "react-redux";
import login from "store/admin/admin.actions";
import {
    Button,
    FormGroup,
    FormControl,
    FormLabel,
    Col,
    Row,
} from "react-bootstrap";
import { NewsItemProps } from "types/newsitem.types";
import styled from "styled-components";
import NewsImage from "../NewsImage/NewsImage";

const NewsForm: React.FC<NewsItemProps> = ({
    href,
    logo_url,
    deleteThis,
    changeNewsItem,
}) => {
    const [hrev, setHrev] = useState(href);
    const [url, setUrl] = useState(logo_url);
    const [imageUrl, setImageUrl] = useState(logo_url);
    const [href_old, setHrefOld] = useState(href);
    const canSendForm = hrev.length > 0 || url.length > 0;
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // change the newsitemsend
        changeNewsItem({
            href_old,
            href_new: hrev,
            logo_url: url,
        });
        setImageUrl(url);
        setHrefOld(hrev);
    };

    return (
        <form onSubmit={handleSubmit} key={hrev}>
            <Row>
                <Col>
                    <NewsImage logo_url={imageUrl} />
                </Col>
                <Col>
                    <NewsFormStyle>
                        <FormGroup controlId={hrev}>
                            <FormLabel>News URL:</FormLabel>
                            <FormControl
                                autoFocus
                                type={hrev}
                                value={hrev}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => setHrev(e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup controlId={logo_url}>
                            <FormLabel>Image URL:</FormLabel>
                            <FormControl
                                value={url}
                                type={logo_url}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => setUrl(e.target.value)}
                            />
                        </FormGroup>
                        <Row>
                            <StyledButton
                                variant="outline-success"
                                block
                                disabled={!canSendForm}
                                type="submit"
                            >
                                Save
                            </StyledButton>

                            <StyledButton
                                variant="outline-danger"
                                onClick={() =>
                                    deleteThis({
                                        href,
                                        logo_url,
                                    })
                                }
                            >
                                Remove
                            </StyledButton>
                        </Row>
                    </NewsFormStyle>
                </Col>
            </Row>
        </form>
    );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {
    login,
};
const ConnectedNewsForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(NewsForm);

const StyledButton = styled(Button)`
    max-width: 6rem;
    margin-left: 1rem;
`;

const NewsFormStyle = styled.div`
    padding: 1em;
    width: 35rem;
`;

export default ConnectedNewsForm;
