import React from "react";
import Logo from "components/Logo/Logo";
import ConnectedLoginForm from "components/LoginForm/LoginForm";
import styled from "styled-components";

const LoginSection: React.FC<{}> = () => {
    return (
        <div>
            <header>
                <Logo />
                <LoginForm>
                    <ConnectedLoginForm />
                </LoginForm>
            </header>
        </div>
    );
};

const LoginForm = styled.div`
    max-width: 50%;
    margin-left: 25%;
`;

export default LoginSection;
