import React, { useEffect, useState } from "react";
import NewsItem from "containers/NewsItem/NewsItem";
import { Button, Card, ListGroup, ListGroupItem } from "react-bootstrap";
import { checkLoggedIn } from "store/admin/admin.actions";
import { connect } from "react-redux";
import { NewsItemReceived, NewsItemSend } from "../../types/newsitem.types";
import {
    getFromAPIWithAuth,
    postToAPIWithAuth,
    delToAPIWithAuth,
    putToAPIWithAuth,
} from "../../utils/apiEndpoint";
import { NewsItemPrompt } from "./NewsSection.components";

const endpoint = "/api/admin/content/news";

const addNewsItem = async (
    newsItem: NewsItemReceived,
    token: string,
    email: string
) => {
    postToAPIWithAuth<NewsItemReceived>(endpoint, email, token, newsItem);
};

const removeNewsItem = async (
    newsItem: NewsItemReceived,
    token: string,
    email: string
) => {
    delToAPIWithAuth<NewsItemReceived>(endpoint, email, token, newsItem);
};

const swapNewsItem = async (
    newsItem: NewsItemSend,
    token: string,
    email: string
) => {
    putToAPIWithAuth<NewsItemSend>(endpoint, email, token, newsItem);
};

const NewsSection: React.FC<{
    token: string;
    email: string;
    checkLoggedIn: Function;
}> = ({ token, email, checkLoggedIn }) => {
    const [newsItems, setNewsItems] = useState();
    const [isAddingNewsItem, setIsAddingNewsItem] = useState(false);
    const [update, doUpdate] = useState(true);
    const updateList = () => setTimeout(() => doUpdate(!update), 100);

    useEffect(() => {
        const func = async () => {
            if (!email || !token) await checkLoggedIn();
        };
        func();
    }, [email, token, checkLoggedIn]);

    useEffect(() => {
        const func = async () => {
            const loadedEmail = email || localStorage.getItem("email");
            if (loadedEmail) {
                const newsApiData: NewsItemReceived[] = await getFromAPIWithAuth(
                    "/api/public/content/news",
                    loadedEmail
                );
                setNewsItems(newsApiData);
            }
        };
        func();
    }, [email, update]);

    const addNews = (newsItem: NewsItemReceived) => {
        addNewsItem(newsItem, token, email);
        setIsAddingNewsItem(false);
        updateList();
    };

    const removeNews = (newsItem: NewsItemReceived) => {
        removeNewsItem(newsItem, token, email);
        updateList();
    };

    const changeNewsItem = (newsItem: NewsItemSend) => {
        swapNewsItem(newsItem, token, email);
        updateList();
    };

    return (
        <div>
            <Card>
                <ListGroup>
                    {newsItems?.map((newsItem: NewsItemReceived) => (
                        <NewsItem
                            key={newsItem.href}
                            href={newsItem.href}
                            logo_url={newsItem.logo_url}
                            deleteThis={removeNews}
                            changeNewsItem={changeNewsItem}
                        />
                    ))}
                    <ListGroupItem>
                        {!isAddingNewsItem ? (
                            <Button
                                variant="outline-success"
                                onClick={() => setIsAddingNewsItem(true)}
                            >
                                Add News
                            </Button>
                        ) : (
                            <NewsItemPrompt handleSubmit={addNews} />
                        )}
                    </ListGroupItem>
                </ListGroup>
            </Card>
        </div>
    );
};

const mapStateToProps = (state: {
    admin: { authToken: string; username: string };
}) => ({
    token: state.admin.authToken,
    email: state.admin.username,
});
const mapDispatchToProps = {
    checkLoggedIn,
};
const ConnectedNewsSection = connect(
    mapStateToProps,
    mapDispatchToProps
)(NewsSection);

export default ConnectedNewsSection;
