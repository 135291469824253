import React from "react";
import styled from "styled-components";

const NewsImage: React.FC<NewsImageProps> = ({ logo_url }) => (
    // eslint-disable-next-line global-require
    <StyledImage src={logo_url} />
);

const StyledImage = styled.img`
    width: 12rem;
    height: 12rem;
    display: inline-block;
    padding: 1rem;
    margin-top: 0rem;
    margin-left: 2rem;
`;

export default NewsImage;

interface NewsImageProps {
    logo_url: string;
}
