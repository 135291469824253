import React from "react";
import * as Sentry from "@sentry/browser";
import MainRouter from "./routes/Router";
import { SENTRY_DSN } from "./config";

Sentry.init({ dsn: SENTRY_DSN });

const AdminDashboard = () => <MainRouter />;

export default AdminDashboard;
