import React, { useEffect } from "react";
import { connect } from "react-redux";
import { checkLoggedIn } from "store/admin/admin.actions";

const Home: React.FC<HomeProps> = ({ checkLoggedIn }) => {
    useEffect(() => {
        checkLoggedIn();
    }, [checkLoggedIn]);
    return <h2>Please select an item in the top menu.</h2>;
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {
    checkLoggedIn,
};
const ConnectedHome = connect(mapStateToProps, mapDispatchToProps)(Home);

export default ConnectedHome;

interface HomeProps {
    checkLoggedIn: () => void;
}
