import React from "react";
import ConnectedContestantSection from "sections/ContestantSection/ContestantSection";
import { NabarButton } from "../../layouts/MainLayout/MainLayout.components";

const Contestants = () => (
    <div>
        <h1>
            Contestants
            <NabarButton navigateToPage="/contestants/add" text="add" />
        </h1>
        <ConnectedContestantSection />
    </div>
);

export default Contestants;
