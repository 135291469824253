import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import MainLayout from "layouts/MainLayout/MainLayout";
import requireAuth from "utils/auth";
import Home from "pages/Home/Home";
import Login from "pages/Login/Login";
import Contestants from "pages/Contestants/Contestants";
import ContestantsAdd from "pages/Contestants/ContestantsAdd";
import News from "pages/News/News";
import Videos from "pages/Videos/Videos";
import Rounds from "pages/Rounds/Rounds";

const MainRouter = () => (
    <Router>
        <MainLayout>
            <Switch>
                <Route path="/home" render={requireAuth(Home)} />
                <Route exact path="/" render={requireAuth(Home)} />
                <Route path="/login">
                    <Login />
                </Route>
                <Route path="/contestants/add">
                    <ContestantsAdd />
                </Route>
                <Route path="/contestants">
                    <Contestants />
                </Route>
                <Route path="/news">
                    <News />
                </Route>
                <Route path="/videos">
                    <Videos />
                </Route>
                <Route path="/rounds">
                    <Rounds />
                </Route>
                <Route path="/test">
                    <Home />
                </Route>
            </Switch>
        </MainLayout>
    </Router>
);

export default MainRouter;
