import { Dispatch } from "redux";
import { LoginUser } from "types/login.types";
import axios from "axios";
import {
    delToAPIWithAuth,
    getFromAPIWithAuth,
    postToAPIWithAuth,
    putToAPIWithAuth,
} from "utils/apiEndpoint";
import { Contestant } from "types/contestantItem.types";
import AdminActions from "./admin.constants";
import { API_URL } from "../../config";
import AuthenticationActions from "./constants";
import VotingRounds from "../../types/votingrounds.types";
import exportCSV from "../../utils/csv";

export const login = (userInfo: LoginUser) => async (dispatch: Dispatch) => {
    try {
        const endpoint = `${API_URL}/api/public/admin/auth`;
        const res = await axios.post(endpoint, userInfo);
        const { token } = res.data.data;
        localStorage.setItem("token", token);
        localStorage.setItem("email", userInfo.email);
        return dispatch({
            type: AdminActions.AUTHENTICATE,
            payload: {
                isLoggedIn: true,
                authToken: token,
                username: userInfo.email,
            },
        });
    } catch (error) {
        return dispatch({
            type: AuthenticationActions.LOGIN_ERROR,
            payload: {
                isLoggedIn: false,
                authToken: null,
                username: null,
            },
        });
    }
};

export const logout = () => async (dispatch: Dispatch) => {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    return dispatch({
        type: AdminActions.AUTHENTICATE,
        payload: {
            isLoggedIn: false,
            authToken: null,
            username: null,
        },
    });
};

export const checkLoggedIn = () => async (dispatch: Dispatch) => {
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email");
    if (token && email) {
        return dispatch({
            type: AdminActions.AUTHENTICATE,
            payload: {
                isLoggedIn: true,
                authToken: token,
                username: email,
            },
        });
    }
    return dispatch({
        type: AdminActions.AUTHENTICATE,
        payload: {
            isLoggedIn: false,
            authToken: null,
            username: null,
        },
    });
};

export const getContestants = () => async (dispatch: Dispatch) => {
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email");
    if (token && email) {
        const res = await getFromAPIWithAuth(
            "/api/player/free/contestants",
            email,
            token
        );
        return dispatch({
            type: AdminActions.UPDATE_CONTESTANTS,
            payload: {
                contestants: res,
            },
        });
    }
    return dispatch({
        type: AdminActions.AUTHENTICATE,
        payload: {
            isLoggedIn: false,
            authToken: null,
            username: null,
        },
    });
};

export const addContestant = (contestant: Contestant) => async (
    dispatch: Dispatch
) => {
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email");
    if (token && email) {
        const res = await postToAPIWithAuth(
            "/api/admin/contestants",
            email,
            token,
            contestant
        );
        return dispatch({
            type: AdminActions.UPDATE_CONTESTANTS,
            payload: {
                contestants: res,
            },
        });
    }
    return dispatch({
        type: AdminActions.AUTHENTICATE,
        payload: {
            isLoggedIn: false,
            authToken: null,
            username: null,
        },
    });
};

export const postContestants = (contestants: Contestant[]) => async (
    dispatch: Dispatch
) => {
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email");
    if (token && email) {
        const res = await putToAPIWithAuth(
            "/api/admin/contestants",
            email,
            token,
            contestants
        );
        return dispatch({
            type: AdminActions.UPDATE_CONTESTANTS,
            payload: {
                contestants: res,
            },
        });
    }
    return dispatch({
        type: AdminActions.AUTHENTICATE,
        payload: {
            isLoggedIn: false,
            authToken: null,
            username: null,
        },
    });
};

export const deleteContestant = (countryName: string) => async (
    dispatch: Dispatch
) => {
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email");
    if (token && email) {
        const res = await delToAPIWithAuth(
            "/api/admin/contestants",
            email,
            token,
            { country: countryName }
        );
        return dispatch({
            type: AdminActions.UPDATE_CONTESTANTS,
            payload: {
                contestants: res,
            },
        });
    }
    return dispatch({
        type: AdminActions.AUTHENTICATE,
        payload: {
            isLoggedIn: false,
            authToken: null,
            username: null,
        },
    });
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const votingRoundPutToVotingRounds = (res: any): VotingRounds => ({
    semifinal_1: res.find((round: any) => round.voting_round === "semifinal_1")
        ?.voting_closed,
    semifinal_2: res.find((round: any) => round.voting_round === "semifinal_2")
        ?.voting_closed,
    grandfinal: res.find((round: any) => round.voting_round === "grandfinal")
        ?.voting_closed,
});
/* eslint-enable @typescript-eslint/no-explicit-any */

export const fetchVotingRounds = () => async (dispatch: Dispatch) => {
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email");
    if (token && email) {
        const res = await getFromAPIWithAuth(
            "/api/player/free/votes/rounds",
            email,
            token
        );
        const newVotingRounds = votingRoundPutToVotingRounds(res);
        dispatch({
            type: AdminActions.UPDATE_VOTING_ROUNDS,
            payload: {
                rounds: newVotingRounds,
            },
        });
    }
};

export const postVotingRounds = (votingRounds: VotingRounds) => async (
    dispatch: Dispatch
) => {
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email");
    if (token && email) {
        const res = await putToAPIWithAuth(
            "/api/admin/voting-rounds",
            email,
            token,
            votingRounds
        );
        const newVotingRounds = votingRoundPutToVotingRounds(res);
        return dispatch({
            type: AdminActions.UPDATE_VOTING_ROUNDS,
            payload: {
                rounds: newVotingRounds,
            },
        });
    }
    return dispatch({
        type: AdminActions.AUTHENTICATE,
        payload: {
            isLoggedIn: false,
            authToken: null,
            username: null,
        },
    });
};

export const downloadVotesCSV = () => async (dispatch: Dispatch) => {
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email");
    if (token && email) {
        const res = await getFromAPIWithAuth(
            "/api/admin/votes-export",
            email,
            token
        );
        return exportCSV(res.csv_data);
    }
    return dispatch({
        type: AdminActions.AUTHENTICATE,
        payload: {
            isLoggedIn: false,
            authToken: null,
            username: null,
        },
    });
};

export default login;
