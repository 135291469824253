import React from "react";
import { connect } from "react-redux";
import AdminState from "store/admin/admin.types";
import ReduxStore from "store/store.types";
import { downloadVotesCSV, logout } from "store/admin/admin.actions";
import {
    LayoutContainer,
    MainLayoutContentsContainer,
} from "./MainLayout.styles";
import { MainNavbar } from "./MainLayout.components";

const MainLayout: React.FC<{
    children: JSX.Element;
    admin: AdminState;
    logout: Function;
    downloadVotesCSV: () => void;
}> = ({ children, admin, logout, downloadVotesCSV }) => (
    <LayoutContainer>
        {admin.isLoggedIn ? (
            <MainNavbar logout={logout} downloadVotesCSV={downloadVotesCSV} />
        ) : null}
        <MainLayoutContentsContainer>{children}</MainLayoutContentsContainer>
    </LayoutContainer>
);

const mapStateToProps = (state: ReduxStore) => ({
    admin: state.admin,
});
const mapDispatchToProps = {
    logout,
    downloadVotesCSV,
};

const ConnectedMainLayout = connect(
    mapStateToProps,
    mapDispatchToProps
)(MainLayout);

export default ConnectedMainLayout;
